import React from "react"
import Image from "next/image"
import { Button } from "@components/common/Button"

interface InsuranceButtonProps {
  onCheckoutToInsurance: () => void
}

const InsuranceButton: React.FC<InsuranceButtonProps> = ({ onCheckoutToInsurance }) => {
  return (
    <div className="mb-4 bg-white rounded-lg p-4 shadow-sm border border-gray-100">
      <div className="flex justify-between items-center">
        <div className="flex-grow">
          <h3 className="text-2xl font-bold text-dark1 mb-1">Fastic Plus Gratis!</h3>
          <p className="text-gray-600 mb-3">Dank deiner Krankenkasse!</p>
          <Button
            primary
            label="Mehr erfahren"
            onClick={onCheckoutToInsurance}
            className="w-full sm:w-auto bg-emerald-500 hover:bg-emerald-600"
          />
        </div>
        <div className="ml-4 flex-shrink-0">
          <Image
            src="/images/paywall/fasty_doc.svg"
            alt="Fasty Insurance Mascot"
            width={100}
            height={100}
            className=""
          />
        </div>
      </div>
    </div>
  )
}

export default InsuranceButton
